import w from "./utils/window";
import { COOKIES, BASE_URL } from "./constants";
import {
  oneTrustInit,
  isConsented as oneTrustConsented,
  injectPriorityOneTrustScript,
  openOneTrustPreferences,
  insertOneTrustScript,
  oneTrustGetConsentedValue,
  onceConsented as oneTrustOnceConsented,
} from "./onetrust/onetrust-cookie-consent";
import { legacyOneTrustInit } from "./onetrust/onetrust-legacy";

import type { CookieGroupType } from "./types";

type CookieGroup = CookieGroupType;

export { COOKIES, CookieGroupType, CookieGroup };

export const init = async (token: string, src = BASE_URL): Promise<void> => {
  if (
    (w && !w.websiteCookieConsent) ||
    (w?.websiteCookieConsent && !w.websiteCookieConsent.loaded)
  ) {
    legacyOneTrustInit();
  }
  oneTrustInit(token, src);
};

export const consented = (cookieGroup: CookieGroupType, description: string): boolean =>
  oneTrustConsented(cookieGroup, description);

/*
This script is used in order to render the CookieConsentCheck function inline,
to be able to do the cookie check as quick as possible and inject third party scripts much faster.
*/
export const injectPriorityScript = (
  group: CookieGroupType,
  scriptSrc: string,
  description: string
): string => injectPriorityOneTrustScript(group, scriptSrc, description);

export const openPreferences = (): void => openOneTrustPreferences();

export const insertScript = (
  src: string,
  cookieGroup: CookieGroupType,
  description: string
): void => insertOneTrustScript(src, cookieGroup, description);

export const onceConsented = (
  cookieGroup: CookieGroupType,
  description: string
): Promise<void | void[]> => oneTrustOnceConsented(cookieGroup, description);

export const getConsentedValue = () => oneTrustGetConsentedValue();
