import { normalize } from "./normalize";

export const logToSplunk = (activeGroups: string, originalGroups: string) => {
  if (originalGroups === "") {
    return;
  }

  const acceptAll = normalize(["C0001", "C0002", "C0003", "C0004"]);
  const reject = "C0001";

  // Avoid logging without user has either rejected all, consented all or made changes.
  let change = null;

  if (activeGroups === acceptAll) {
    change = "Consent All";
  } else if (activeGroups === reject) {
    change = "Reject All";
  } else {
    change = "Change Consent";
  }

  if (change) {
    logInfo("Cookie Consent", `${change} - ${activeGroups}`);
  }
};

export const logInfo = (title: string, message: string) => {
  fetch("/.api/frontend-logger-service/v1/log", {
    method: "POST",
    keepalive: true,
    body: JSON.stringify({
      logLevel: "INFO",
      message,
      title,
    }),
    headers: { "Content-Type": "application/json" },
  });
};
