import w from "./window";
import { ONETRUST_LOADED_EVENT } from "../constants";

export const OneTrustLoaded = (): boolean =>
  typeof w?.OptanonWrapper !== "undefined" && typeof w?.OnetrustActiveGroups !== "undefined";

export const consentChangedEvent = (): void => {
  const event = new CustomEvent(ONETRUST_LOADED_EVENT);
  w?.dispatchEvent(event);
};
