import w from "../utils/window";
import { ONETRUST_COOKIE_PREFERENCES_OPEN_EVENT, COOKIES } from "../constants";
import { isConsented } from "./onetrust-cookie-consent";

type Callback = () => void;
export type Value = [
  key: string,
  fallback: string | undefined,
  successCallback: Callback,
  errorCallback: Callback
];

/**
 * @namespace consent-api
 * @description Defines the public API that handles consent. The API is defined globally on window preferably in syncronously inside <head>.
 * When this script is loaded later, we take the items in the queue and check the consent for each item.
 * The API exposes two functions:
 * - window.websiteCookieConsent.get(String nameOfTheService, int fallbackLevel). This function returns an object with a function then(function successCallback, function errorCallback) in it.
 * - window.websiteCookieConsent.openConsentPreferences. Opens the OneTrust cookie preferences modal.
 */

// Handles the calls to window.websiteCookieConsent.get(). If we have consent, run successCallback, otherwise errorCallback.
export const handleGet = (value: Value): void => {
  const key = value[0];
  const fallback = value[1];
  const successCallback = value[2];
  const errorCallback = value[3];

  if (fallback !== undefined) {
    console.warn(`Website-cookie-consent: Fallback levels are no longer in use`);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isConsented(COOKIES[key], "Legacy API") && typeof successCallback === "function") {
    successCallback();
  } else if (typeof errorCallback === "function") {
    errorCallback();
  }
};

// This makes packages that rely on the old Cookie Consent work during the migration of ntse
// The init method is exposed in the root file of this package and should be used once packages that rely on consent consume this package instea of the legacy one.
const legacyOneTrustInit = (): void => {
  if (!w) return;

  // TODO:
  //init(token, src);

  const globalName = "websiteCookieConsent";
  // Internal reference to the api for easier use
  let api = w[globalName];

  // Create api if it is not already set
  if (!api) {
    api = {
      get(key, fallbackLevel) {
        return {
          then(successCallback, errorCallback) {
            api.q.push([key, fallbackLevel, successCallback, errorCallback]);
          },
        };
      },
      q: [],
      config: {},
    };
    w[globalName] = api;
  }
  // Copy the queue before overwriting it with the handleGet function. This way we don't lose calls to .get() that are made during the time we handle the queue.
  const copy = api.q;

  // Make direct calls to handleGet once script is loaded
  api.q = {
    push: (value: Value) => handleGet(value),
  };

  // Handle all requests from the copy.
  Array.isArray(copy) &&
    copy.forEach((value) => {
      handleGet(value);
    });

  /* istanbul ignore next */
  api.openConsentPreferences = () => {
    if (w && w.OneTrust) {
      w.OneTrust.ToggleInfoDisplay();

      const event = new CustomEvent(ONETRUST_COOKIE_PREFERENCES_OPEN_EVENT);
      window.dispatchEvent(event);
    }
  };

  // Set some extra parameters
  api.loaded = true;
  api.version = "legacy"; // the `website-cookie-consent` package is still being used. This is only for the telia.se domain.
};

export { legacyOneTrustInit };
